import {Box, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {Computer, FmdGood, GitHub, Group, Instagram, Mail} from "@mui/icons-material";
import React from "react";

export function MyFooter() {

    return <Grid marginTop={0} container direction="column" justifyContent="center" alignItems="center">
        <Grid container spacing={0}>
            <Grid item xs={12} sm={6} md={6}>
                <Grid container component={"span"} direction="column" justifyContent="center" alignItems="center"
                      marginTop={4} marginBottom={4}>
                    <Grid item>
                        <Box component={"img"} sx={{height: "auto", maxHeight: "50px", maxWidth: "100%",}}
                             alt="LOGO"
                             src="/images/_.png"
                        />
                        <Typography variant={"body1"} fontStyle={"italic"} marginTop={2} marginBottom={2}>
                            Follow me:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton color={"secondary"} onClick={() => window.open("https://www.instagram.com/bela")}><Instagram
                            htmlColor={"white"} fontSize={"large"}/></IconButton>
                        <IconButton color={"secondary"} onClick={() => window.open("https://.com/")}><GitHub htmlColor={"white"}
                                                                                                             fontSize={"large"}/></IconButton>
                        <IconButton color={"secondary"} onClick={() => window.open("https://.hu/")}><Computer htmlColor={"white"}
                                                                                                                              fontSize={"large"}/></IconButton>
                        <IconButton color={"secondary"} onClick={() => window.open("https://.hu/")}><Mail htmlColor={"white"}
                                                                                                                          fontSize={"large"}/></IconButton>
                        <IconButton color={"secondary"} onClick={() => window.open("https://.com/")}><Group htmlColor={"white"}
                                                                                                                    fontSize={"large"}/></IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Typography variant={"h5"} color={"white"} marginTop={8} marginBottom={0} align={"center"}
                            fontWeight={"bold"}
                >
                    About
                </Typography>
                <List>
                    <ListItem key={0} alignItems={"flex-start"}>
                        <ListItemIcon><FmdGood color={"warning"}/></ListItemIcon>
                        <ListItemText>Hungary - Budapest</ListItemText>
                    </ListItem>
                    <ListItem key={3} alignItems={"flex-start"}>
                        <ListItemIcon><Mail color={"warning"}/></ListItemIcon>
                        <ListItemText>Send message</ListItemText>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    </Grid>
}