import {HOST_API_URL, HttpService} from "./HttpService";
import {AxiosResponse} from "axios";

class ArticleListService extends HttpService {

    constructor() {
        super()
    }

    getArticles(category: string | undefined, callback: (status: number, resp: AxiosResponse) => void): Object {
        this.httpClient.get(HOST_API_URL + "article-list", {
            params: {
                category: category
            }
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch(function (error: any) {
                // handle error
                console.log('hiba' + error)
            })

        return ""
    }

    getItemCount(category: string | undefined, callback: (status: number, resp: AxiosResponse) => void): Object {
        this.httpClient.get(HOST_API_URL + "article-list/count", {
            params: {
                category: category
            }
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch(function (error: any) {
                // handle error
                console.log('hiba' + error)
            })

        return ""
    }

    getArticlesAtPage(category: string, page: number, itemsPerPage: number, callback: (status: number, resp: AxiosResponse) => void): Object {
        this.httpClient.get(HOST_API_URL + "article-list/page", {
            params: {
                category: category,
                page: page,
                'items-per-page': itemsPerPage,
            }
        })
            .then((resp: AxiosResponse) => {
                callback(resp.status, resp)
            })
            .catch(function (error: any) {
                // handle error
                console.log('hiba' + error)
            })

        return ""
    }
}

const articleListService = new ArticleListService()
export default articleListService