import {HOST_API_URL, HttpService} from "./HttpService";
import {AxiosResponse} from "axios";

class ArticleService extends HttpService {

    constructor() {
        super()
    }

    get(path: string, callback: (resp: AxiosResponse) => void): Object {
        this.httpClient.get(HOST_API_URL + "article", {
            params: {
                path: path
            }
        })
            .then((resp: AxiosResponse) => {
                callback(resp)
            })
            .catch(function (error: any) {
                // handle error
                console.log('hiba' + error)
            })

        return ""
    }
}

const articleService = new ArticleService()
export default articleService