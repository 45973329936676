import {Grid} from "@mui/material";
import React from "react";
import {ArticleListPage, ArticleListPageType} from "../gecsevar_framework/components/ArticleListPage";
import {gecsevarConfig} from "../gecsevar_framework/pages/MainPage";
import {MyExamToolbox} from "./MyExamToolbox";

export function MyDashboard() {

    return <MyExamToolbox/>
}

/*
    <Grid container margin={0} spacing={0} direction={"row"} justifyContent={"center"} alignItems={"stretch"}>
        <Grid item xs={12} md={6} lg={6} >
            <ArticleListPage type={ArticleListPageType.dashboard} category={gecsevarConfig.getDefaultArticleListCategory()} />
        </Grid>
        <Grid item xs={12} md={6} lg={6} >
            <ArticleListPage type={ArticleListPageType.dashboard} category={"Kotlin"} />
        </Grid>
    </Grid>

 */