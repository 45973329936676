import {createTheme} from "@mui/material";
import {blue, orange, red} from "@mui/material/colors";
import MontserratVTT from "../../assets/fonts/Montserrat-VariableFont_wght.ttf"
import MontserratReg from "../../assets/fonts/Montserrat-Regular.ttf"
import Roboto from "../../assets/fonts/Roboto-Light.ttf"

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        }
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        }
        customHoverFocus?: {
            "&:hover, &.Mui-focusVisible"?: object
        }
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        dashed: true;
    }
}

// App.css -> @font-face | To add fonts
export const gecsevarTheme = createTheme({
    typography: {
        fontFamily: 'Montserrat-Regular',
        fontWeightLight: 'true'
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#5893df',
        },
        secondary: {
            main: '#2ec5d3',
        },
        background: {
            default: '#192231',
            paper: '#24344d',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
              font-family: "Roboto";
              src: local("Roboto-Light.ttf"), url(${Roboto}) format("truetype");
            }
            @font-face {
              font-family: "Montserrat-Regular";
              src: local("Montserrat-Regular.ttf"), url(${MontserratReg}) format("truetype");
            }
            @font-face {
              font-family: "Montserrat-Light";
              src: local("Montserrat-Light.ttf"), url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
            }
            @font-face {
              font-family: "Montserrat-VTT";
              src: local("Montserrat-VariableFont_wght.ttf"), url(${MontserratVTT}) format("truetype");
            }
            `
        },
        MuiIconButton: {
            defaultProps: {
                color: "primary",
            },
        },
        MuiButton: {
            variants: [
                {
                    props: {variant: 'dashed'},
                    style: {
                        textTransform: 'none',
                        border: `2px dashed ${blue[500]}`,
                    },
                },
                {
                    props: {variant: 'dashed', color: 'secondary'},
                    style: {
                        border: `4px dashed ${red[500]}`,
                    },
                },
            ],
        },
        MuiLink: {
            defaultProps: {
                style: {
                    cursor: 'pointer'
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    color: 'white',
                    '&:hover': {
                        color: '#fdc800'
                    }
                }
            }
        },
        MuiListItemButton: {
            defaultProps: {
                disableTouchRipple: true,
                style: {}
            },
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        background: '#004167',
                        color: 'lightGreen'
                    },
                    '&.Mui-focusVisible': {
                        background: '#2e8b57'
                    },
                    "&:hover": {
                        background: '#002147',
                    },
                },
            },
        },
    },
    status: {
        danger: orange[500],
    },
    customHoverFocus: {
        "&:hover, &.Mui-focusVisible": {color: "#fdc800", backgroundColor: "#002147"}
    },
})
