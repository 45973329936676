import {Checkbox, FormControlLabel, FormGroup, FormLabel} from "@mui/material";

export function MultiSelect() {

    return <>
        <FormLabel id="demo-radio-buttons-group-label">Válassza ki a helyes választ! (több válasz is lehetséges)</FormLabel>
        <FormGroup>
            <FormControlLabel control={<Checkbox />} label="Ehető"/>
            <FormControlLabel control={<Checkbox />} label="Iható"/>
            <FormControlLabel control={<Checkbox />} label="Látható"/>
        </FormGroup>
    </>
}