import {Box, Grid, Paper, Toolbar} from "@mui/material";
import React from "react";
import {Route, Routes} from "react-router-dom";
import {Content} from "./Content";
import {ArticleListPage, ArticleListPageType} from "./ArticleListPage";
import {gecsevarConfig} from "../pages/MainPage";

export const ContentPath = "/content/"
export const ArticleListPath = "/article_list/"

type  ContentProps = {}

export function Container(_: ContentProps) {

    return (<Box component={"main"} sx={{
            p: 0,
            background: gecsevarConfig.getContainerBackground(),
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
        }}>
            <Toolbar sx={{marginTop: 1}}/>
            <Box marginTop={1} marginBottom={0}>
                <Grid container spacing={0}>
                    <Grid item xs={0} sm={1} md={1} sx={{}}>
                    </Grid>
                    <Grid item xs={12} sm={10} md={10}>
                        <Box sx={{
                            p: 0,
                            background: gecsevarConfig.getContainerInnerGridBackground(),
                            m: 0
                        }}>
                            <Paper elevation={0} sx={{
                                p: "1px",
                                marginTop: 0,
                                marginBottom: 1,
                                backgroundColor: gecsevarConfig.getContainerInnerPaperBackground()
                            }}>
                                <Routes>
                                    <Route key={"A1"} path={"/"} element={gecsevarConfig.getDashboard()}></Route>
                                    <Route key={"A2"} path={`${ContentPath}:path`} element={<Content key={1}/>}></Route>
                                    <Route key={"A5"} path={`${ArticleListPath}:category`} element={<ArticleListPage key={1} type={ArticleListPageType.page}
                                                                                                                     category={gecsevarConfig.getDefaultArticleListCategory()}/>}></Route>
                                    {gecsevarConfig.getHeaderButtons().map((value, index) => (
                                        <Route key={index} path={value.route} element={gecsevarConfig.getHeaderButtonElementBy(value.element)}></Route>
                                    ))
                                    }
                                </Routes>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item xs={0} sm={1} md={1} sx={{}}>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
