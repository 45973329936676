import React from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter} from "react-router-dom";
import {MainPage} from "./gecsevar_framework/pages/MainPage";

function App() {
  return <React.StrictMode>
    <BrowserRouter>
      <MainPage/>
    </BrowserRouter>
  </React.StrictMode>
}

export default App;
