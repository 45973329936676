import menuService from "../services/MenuService";
import {AxiosResponse} from "axios";
import {instanceToInstance} from "class-transformer";
import {MyFooter} from "../../pages/MyFooter";
import {MyCopyright} from "../../pages/MyCopyright";
import {MyDashboard} from "../../pages/MyDashboard";
import {DrawerMenuModel} from "../models/DrawerMenuModel";

export type HeaderButton = {
    title: string,
    route: string,
    element: string;
}

export class GecsevarConfig {

    private initialized = false
    private menuMap = new Array<DrawerMenuModel>()
    private headerButtons = new Array<HeaderButton>()

    constructor() {

    }

    initialize(initCallback: () => void) {
        menuService.get((resp: AxiosResponse) => {
            this.menuMap = instanceToInstance(resp.data)
            this.initialized = true
            initCallback()
        })
    }

    getHeaderLogo(size: string) {
        if (size === "xs") {
            return "/images/_.png"
        } else if (size === "sm") {
            return "/images/_.png"
        }
    }

    getHeaderButtons() {
        if (!this.initialized)
            console.error("Gecsevar Config Uninitialized!")
        return this.headerButtons
    }

    getHeaderButtonElementBy(name: string) {
        if (!this.initialized)
            console.error("Gecsevar Config Uninitialized!")
        switch (name) {
            case 'Uzenetkuldes' :
                return <div/>
            case 'Fogadoest'    :
                return <div/>
        }
        console.error('Missing Element for: ' + name)
        return <></>
    }

    getMenuTitle() {
        return "Content"
    }

    getMenuBackground() {
        return 'linear-gradient(to top, #002147aa, #002147dd), url(/images/natures-beauty-reflected-tranquil-mountain-waters-generative-ai.jpg) no-repeat top center'
    }

    getContainerBackground() {
        return ''   // default
    }

    getContainerInnerGridBackground() {
        return ''   // default
    }

    getContainerInnerPaperBackground() {
        return ''   // default
    }

    getArticleListPageBackground() {
        return 'primary.dark'
    }

    getMenuNavigation(): Array<DrawerMenuModel> {
        if (!this.initialized)
            console.error("Gecsevar Config Uninitialized!")
        return this.menuMap.filter(value => {
            return value.level !== "submenu"
        })
    }

    getSubMenuFor(menuId: string): Array<DrawerMenuModel> {
        if (!this.initialized)
            console.error("Gecsevar Config Uninitialized!")
        let inZone = false;
        const temp: Array<DrawerMenuModel> = [];
        this.menuMap.map(value => {
            if (value.id === menuId) {
                inZone = true
            } else if (inZone && value.level === "submenu") {
                temp.push(value)
            } else if (inZone && value.level !== "submenu") {
                inZone = false
            }
            return null
        })

        return temp
    }

    getDefaultArticleListCategory() {
        return "article"
    }

    getDashboard() {
        return <MyDashboard/>
    }

    getFooterContent() {
        return <MyFooter/>
    }

    getCopyrightContent() {
        return <MyCopyright/>
    }

    getItemsCountOnArticleListPage() {
        return 5
    }
}
