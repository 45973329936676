import React, {useEffect} from "react";
import {Card, CardActionArea, CardContent, CardMedia, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ArticleModel from "../models/ArticleModel";
import {ContentPath} from "./Container";

type MiniNewsCardProps = {
    article: ArticleModel | undefined
}

export function MiniNewsCard(props: MiniNewsCardProps) {

    const navigate = useNavigate()

    useEffect(() => {
    }, []);

    return props.article === undefined ?
        <></>
        :
        <Card key={props.article.id} sx={{margin: "10px"}}>
            <CardActionArea sx={{display: "flex", justifyContent: "space-between"}} onClick={() => {
                if (props?.article?.path?.length !== 0) {
                    navigate(`${ContentPath}${props?.article?.path}`)
                } else {
                    navigate(`${ContentPath}${props?.article?.id}`)
                }
            }}>
                {props.article.imageUrl !== "" ?
                    <CardMedia
                        image={props.article.imageUrl}
                        src={props.article.imageUrl}
                        component="img"
                        title={"title"}
                        sx={{minWidth: "150px", maxWidth: "150px", padding: "1em 1em 1em 1em", objectFit: "contain"}}
                    />
                    :
                    <></>
                }
                <CardContent sx={{flex: '1 2 auto'}}>
                    <Typography gutterBottom variant="h6" component="div">
                        {props.article.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.article.description}...
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
}