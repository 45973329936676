import {Grid, Link, Typography} from "@mui/material";
import React from "react";

export function MyCopyright() {

    return <>
        <Grid item>
        <Typography variant="body2" color="lightgrey" align="left" >
            {'Copyright © '}
            {new Date().getFullYear()} Gecse Va'r | HU
        </Typography>
    </Grid>
    <Grid item>
        <Typography variant="body2" color="lightgrey" align="right" >
            {'Running on: '}
            GecseVa'r React/JS | Kotlin/ktor framework
            <Link color="inherit" href="https://www.gecsevar.hu">

            </Link>
        </Typography>
    </Grid>
        </>
}