import {Checkbox, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup} from "@mui/material";

export function SingleSelect() {

    return <>
        <FormLabel id="demo-radio-buttons-group-label">Válassza ki a helyes választ!</FormLabel>
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
        >
            <FormControlLabel value="female" control={<Radio />} label="Zöld" />
            <FormControlLabel value="male" control={<Radio />} label="Kék" />
            <FormControlLabel value="other" control={<Radio />} label="Jaj nem, mégis zöld" />
        </RadioGroup>
    </>
}