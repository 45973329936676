import {HOST_API_URL, HttpService} from "./HttpService";
import {AxiosResponse} from "axios";

class MenuService extends HttpService {

    constructor() {
        super()
    }

    get(callback: (resp: AxiosResponse) => void): Object {
        this.httpClient.get(HOST_API_URL + "menu", {
            params: {}
        })
            .then((resp: AxiosResponse) => {
                //console.log(resp.data)
                callback(resp)
            })
            .catch(function (error: any) {
                // handle error
                console.log('hiba' + error)
            })

        return ""
    }
}

const menuService = new MenuService()
export default menuService