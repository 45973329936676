import axios, {AxiosInstance, AxiosResponse, InternalAxiosRequestConfig} from "axios";

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

export const DEBUG = isLocalhost   // DEBUG or NOT

export const OAUTH_SERVER_URL = process.env.REACT_APP_OAUTH_SERVER_URL
let HOST_API_URL = process.env.REACT_APP_HOST_API_URL
if (DEBUG) {
    HOST_API_URL = "http://127.0.0.1:9090/"
}
export {HOST_API_URL}

let HOST_URL = process.env.REACT_APP_HOST_URL
if (DEBUG) {
    HOST_URL = "http://localhost:3000/"
}
export {HOST_URL}

export class HttpService {

    protected httpClient: AxiosInstance

    protected constructor() {
        this.httpClient = axios.create()
        this.httpClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
//            if (oauthService.isLoggedIn()) {
//                const cb = () => {
//                config!.headers!.Authorization = `Bearer ${oauthService.getToken()}`
//                return config
//                };
//            }
            return config
        }, (error) => {
            console.log("Error : " + error)
            return Promise.reject(error)
        })
        this.httpClient.interceptors.response.use((value: AxiosResponse) => {
            if (value.status === 401) {
//                oauthService.refreshToken((success) => {
//                    console.log("token refresh result: " + success)
//                })
            }
            return value
        })
    }

    handleError(error: any, refreshToken: (success: boolean) => any) {
        const statusCode = error.response ? error.response.status : null
        switch (statusCode) {
            case 401 : {
                console.log('Unauthorized -> refresh token')
//                oauthService.refreshToken((success) => {
//                    console.log('Refresh token success')
//                    refreshToken(success)
//                })
                break
            }
            case 404 : {
                console.log('404 - not found')
                break
            }
            case 500 : {
                console.log('500 internal error - bad request')
                break
            }
        }
        return statusCode
    }
}

