import {Box, Button, Divider, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField} from "@mui/material";
import threeam from "../assets/audio/threeam.mp3";
import {DragDropContext, Draggable, DropResult} from "react-beautiful-dnd";
import {useState} from "react";
import {getItemStyle, getListStyle, StrictModeDroppable} from "../components/MyDragAndDrop";
import {SingleSelect} from "../components/questions/SingleSelect";
import {MultiSelect} from "../components/questions/MultiSelect"; // react-app-env.d.ts -> declare module '*.mp3';

export function MyExamToolbox() {

    let audio = new Audio(threeam)
    const [dragabbleList, setDraggableList] = useState<Array<string>>(["alma", "körte", "ló", "tehén"])

    const start = () => {
        audio.play()
    }

    const onDragStart = () => {

    }
    const onDragEnd = (menu: Array<string>, result: DropResult): void => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        let temp = menu
        //const [removed] = temp.splice(result.source.index, 1)
        //temp.splice(result.destination.index, 0, removed)
        //temp.forEach((element, index) => {
        //    //temp[index].id = String(index)
        //})
        //setMenuMap(temp)
    }

    return <Box sx={{p: 2}}>
        <Box>
            Schedule - Pass mark - Point(s) for Questions - Time limit - Randomize questions - Multi language - Theme
        </Box>
        <p>
            <SingleSelect/>
        </p>
        <Divider/>
        <p>
            <MultiSelect/>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label">Válaszolja meg!</FormLabel>
            <br/>
            <TextField id="standard-basic" label="válasz" variant="standard"/>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label">Egészítse ki!</FormLabel>
            <br/>
            <Box sx={{display: 'flex', alignItems: 'baseline', flexWrap: 'wrap'}}>
                Itt lehet egy sokkal hosszabb mondat, <TextField id="standard-basic" label="írja be a megfelelő szót" variant="standard"
                                                                 sx={{marginLeft: '5px', marginRight: '5px'}}/>
                amit eddig lehetett volna <TextField id="standard-basic" label="írja be a megfelelő szót" variant="standard"
                                                     sx={{marginLeft: '5px', marginRight: '5px'}}/>
            </Box>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label">Olyan mint az előző csak SELECT</FormLabel>
            <br/>
            <Box sx={{display: 'flex', alignItems: 'baseline', flexWrap: 'wrap'}}>
                Itt lehet egy sokkal hosszabb mondat,
                <Select id="standard-basic" label="válassza ki a megfelelő szót" variant="standard" sx={{marginLeft: '5px', marginRight: '5px'}}>
                    <MenuItem value={10}>mint</MenuItem>
                    <MenuItem value={20}>írunk</MenuItem>
                </Select>
                amit eddig lehetett volna
                <Select id="standard-basic" label="válassza ki a megfelelő szót" variant="standard" sx={{marginLeft: '5px', marginRight: '5px'}}>
                    <MenuItem value={10}>mint</MenuItem>
                    <MenuItem value={20}>írunk</MenuItem>
                </Select>
            </Box>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label">Olyan mint az előző csak SELECT - több elem mint amennyi hely van</FormLabel>
            <br/>
            <Box sx={{display: 'flex', alignItems: 'baseline', flexWrap: 'wrap'}}>
                Itt lehet egy sokkal hosszabb mondat,
                <Select id="standard-basic" label="válassza ki a megfelelő szót" variant="standard" sx={{marginLeft: '5px', marginRight: '5px'}}>
                    <MenuItem value={0}>még</MenuItem>
                    <MenuItem value={10}>mint</MenuItem>
                    <MenuItem value={20}>valamennyi</MenuItem>
                    <MenuItem value={30}>tehén</MenuItem>
                    <MenuItem value={40}>ló</MenuItem>
                </Select>
                amit eddig lehetett volna
                <Select id="standard-basic" label="válassza ki a megfelelő szót" variant="standard" sx={{marginLeft: '5px', marginRight: '5px'}}>
                    <MenuItem value={0}>még</MenuItem>
                    <MenuItem value={10}>mint</MenuItem>
                    <MenuItem value={20}>valamennyi</MenuItem>
                    <MenuItem value={30}>tehén</MenuItem>
                    <MenuItem value={40}>ló</MenuItem>
                </Select>
            </Box>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label">Párosítsa a két oldalon lévő adatokat - értelemszerűen</FormLabel>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={4}>
                    Ló
                    <br/>
                    Tehén
                    <br/>
                    Kutya
                    <br/>
                    Fű
                </Grid>
                <Grid item xs={4}>
                    Zöld
                    <br/>
                    Vágtat
                    <br/>
                    Tejet ad
                    <br/>
                    Ugat
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </Grid>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label">Párosítsa a képhez a szöveget - értelemszerűen</FormLabel>
            <br/>
            Ló - KÉP
            Tehén - KÉP
            Kutya - KÉP
            Fű - KÉP
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label">Hallgassa meg a jobbra lévő szöveget. Válaszoljon, egészítse ki stb...</FormLabel>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={4}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="female" control={<Radio/>} label="Nagyon jó szám"/>
                        <FormControlLabel value="male" control={<Radio/>} label="Kiváló"/>
                        <FormControlLabel value="other" control={<Radio/>} label="Number One"/>
                    </RadioGroup>
                </Grid>
                <Grid item xs={4}>
                    <Button variant={"contained"} onClick={start}>Lejátszás</Button>
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </Grid>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label">A képen látható "valami" válassza ki a helyes megnevezés</FormLabel>
            <br/>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label">A mellékelt videón látható klipp egy:</FormLabel>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={4}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="female" control={<Radio/>} label="Ezen nőttem fel"/>
                        <FormControlLabel value="male" control={<Radio/>} label="Kiváló klipp"/>
                        <FormControlLabel value="other" control={<Radio/>} label="Number One"/>
                    </RadioGroup>

                </Grid>
                <Grid item xs={4}>
                    <iframe width="250" height="200" src="https://www.youtube.com/embed/HDsCeC6f0zc?si=HBKucBW5AeYgAGlt" title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </Grid>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label">Igaz / Hamis . válassze ki a megfelelő</FormLabel>
            <br/>
            A fű zöld -
            <Select id="standard-basic" label="válassza ki a megfelelő szót" variant="standard" sx={{marginLeft: '5px', marginRight: '5px'}}>
                <MenuItem value={0}>Igaz</MenuItem>
                <MenuItem value={10}>Hamis</MenuItem>
            </Select>
            <br/>
            Az ég kék
            <Select id="standard-basic" label="válassza ki a megfelelő szót" variant="standard" sx={{marginLeft: '5px', marginRight: '5px'}}>
                <MenuItem value={0}>Igaz</MenuItem>
                <MenuItem value={10}>Hamis</MenuItem>
            </Select>
            <br/>
            A tehén nyávog
            <Select id="standard-basic" label="válassza ki a megfelelő szót" variant="standard" sx={{marginLeft: '5px', marginRight: '5px'}}>
                <MenuItem value={0}>Igaz</MenuItem>
                <MenuItem value={10}>Hamis</MenuItem>
            </Select>
            <br/>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label">Ez a mondat épphogy ______ mindent vagy mégsem?</FormLabel>
            <br/>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
            >
                <FormControlLabel value="female" control={<Radio/>} label="befoglal"/>
                <FormControlLabel value="male" control={<Radio/>} label="tartalmaz"/>
                <FormControlLabel value="other" control={<Radio/>} label="keres"/>
            </RadioGroup>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label">Rakja át a helyes válaszokat. A nyúl egy ...</FormLabel>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={4}>
                    gyors <br/>
                    lassú <br/>
                    zöld <br/>
                    fehér <br/>
                    hosszú fűlű <br/>
                </Grid>
                <Grid item xs={4}>
                    itt táblázat....
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </Grid>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label">Rakja sorendbe, kezdve a kisebbtől a nagyobbig</FormLabel>
            <br/>
            <DragDropContext onDragStart={onDragStart} onDragEnd={(result) => {
                onDragEnd(dragabbleList, result)
            }}>
                <StrictModeDroppable droppableId="droppable">
                    {(provided, snapshot): JSX.Element => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {dragabbleList.map((item, index) => (
                                <Draggable key={index} draggableId={String(index)} index={index}>
                                    {(provided, snapshot): JSX.Element => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        >
                                            <Box>
                                                Itt vannak az itemek
                                            </Box>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        </div>
                    )}
                </StrictModeDroppable>
            </DragDropContext>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={4}>

                </Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </Grid>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label">Számolja ki</FormLabel>
            <br/>
            <Box sx={{display: 'flex', alignItems: 'baseline', flexWrap: 'wrap'}}>
                5 + 11 = <TextField id="standard-basic" label="eredmény" variant="standard" sx={{marginLeft: '5px', marginRight: '5px'}}/>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'baseline', flexWrap: 'wrap'}}>
                55 + 211 / 17 = <TextField id="standard-basic" label="eredmény" variant="standard" sx={{marginLeft: '5px', marginRight: '5px'}}/>
            </Box>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label">Labelling, jelölje meg a képeket (?)</FormLabel>
            <br/>
            béka
            ló
            tehén

            és három kép
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label">assertion / reason - igaz e az állítás</FormLabel>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    a bolha nagyot ugrik
                </Grid>
                <Grid item xs={2}>
                    mert
                </Grid>
                <Grid item xs={4}>
                    erős a lába
                </Grid>
                <Grid item xs={2}>
                    <Select id="standard-basic" label="válassza ki a megfelelő szót" variant="standard" sx={{marginLeft: '5px', marginRight: '5px'}}>
                        <MenuItem value={0}>Igaz</MenuItem>
                        <MenuItem value={10}>Hamis</MenuItem>
                    </Select>
                </Grid>
            </Grid>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
            <br/>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
            <br/>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
            <br/>
        </p>
        <Divider/>
        <p>
            <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
            <br/>
        </p>
    </Box>
}