import {HeaderBar} from "../components/HeaderBar";
import {Box, CircularProgress, CssBaseline, Grid, PaletteMode, Paper, Stack, ThemeProvider, Typography} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {gecsevarTheme} from "../config/GecsevarTheme";
import {Container} from "../components/Container";
import {GecsevarConfig} from "../config/GecsevarConfig";

export var gecsevarConfig: GecsevarConfig = new GecsevarConfig()

const ColorModeContext = React.createContext({
    toggleColorMode: () => {
    }
});

export function MainPage() {

    const [mode, setMode] = useState<PaletteMode>('dark')
    const [gecsevarConfigInit, setGecsevarConfigInit] = useState(false)

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode: PaletteMode) =>
                    prevMode === 'light' ? 'dark' : 'light',
                )
            },
        }),
        [],
    )

    useEffect(() => {
        gecsevarConfig.initialize(() => {
            // Site config must be loaded to load the menu and site structure!
            setGecsevarConfigInit(true)
        })

    }, [])

    if (!gecsevarConfigInit) {
        return (
            <Box sx={{height: '100vh', width: '100%'}}>
                <Paper elevation={4} sx={{
                    p: 0,
                    m: 0,
                }} component={Stack} direction="column" justifyContent="center">
                    <Box sx={{display: 'flex', justifyContent: 'center', p: 4, color: 'black'}}>
                        <CircularProgress thickness={3} size={50}
                                          sx={{
                                              '.MuiCircularProgress-colorPrimary': {
                                                  left: '43%',
                                                  position: 'absolute',
                                                  top: '44vh',
                                                  color: 'primary.main'
                                              }
                                          }}
                        />
                        <Typography variant={"h4"} fontWeight={"normal"} marginLeft={2}>Loading site config...</Typography>
                    </Box>
                </Paper>
            </Box>
        )
    } else {
        return (
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={gecsevarTheme}>
                    <CssBaseline/>
                    <HeaderBar/>
                    <Container/>
                    <Box sx={{
                        width: '100%',
                        color: 'white',
                        backgroundColor: 'primary.main',
                    }}>
                        {gecsevarConfig.getFooterContent()}
                    </Box>
                    <Grid container component={"span"} sx={{p: 2, background: 'primary.main', display: {xs: 'block', sm: 'flex'}}}
                          justifyContent={"space-between"} alignItems={"center"}>
                        {gecsevarConfig.getCopyrightContent()}
                    </Grid>
                </ThemeProvider>
            </ColorModeContext.Provider>
        )
    }
}