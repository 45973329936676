import React, {RefObject, useEffect, useRef, useState} from "react";
import {Box, Fade} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import articleService from "../services/ArticleService";
import ArticleModel from "../models/ArticleModel";
import DOMPurify from "dompurify";

let data = new ArticleModel();

// CREDITS: https://mtsknn.fi/blog/react-links-in-html/
function useLinkClickHandlers(ref: RefObject<HTMLDivElement>) {
    const navigate = useNavigate()

    useEffect(() => {

        if (!ref.current) return

        const links = ref.current.querySelectorAll('a')
        links.forEach((link) => link.addEventListener('click', handleLinkClick))

        // clean up
        return () => {
            links.forEach((link) => link.removeEventListener('click', handleLinkClick))
        }

        function handleLinkClick(event: MouseEvent) {
            const link = event.currentTarget as HTMLAnchorElement
            const href = link.getAttribute('href')
            // const target = link.getAttribute('target')  // now: 'rel'
            const rel = link.getAttribute("rel")
            const url = new URL(href || '', window.location.origin)

            let isInternalLink = url.origin === window.location.origin
            const isOpenedInSameWindow = rel !== 'noopener' // !target || target === '_self'
            const isLeftButtonClick = event.button === 0
            const isModifierKeyPressed = event.altKey || event.ctrlKey || event.metaKey || event.shiftKey

            if (window.location.origin === "http://localhost:3000") {
                // DEBUG
                isInternalLink = true
            }

            if (isInternalLink && isOpenedInSameWindow && isLeftButtonClick && !isModifierKeyPressed) {
                event.preventDefault()
                navigate(url.pathname + url.search + url.hash)
            }
        }

    }, [navigate, ref]);
}

function HtmlContent({html}: { html: string }) {
    const ref = useRef<HTMLDivElement>(null)
    useLinkClickHandlers(ref)

    return <div dangerouslySetInnerHTML={{__html: html}} ref={ref}/>
}

type ContentProps = {
    path?: string
}

export function Content(props: ContentProps) {

    const ref = useRef<HTMLDivElement>(null)
    useLinkClickHandlers(ref)

    const param = useParams();
    const [loaded, setLoaded] = useState(false)
    const scrollToTop = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    };

    useEffect(() => {
        setLoaded(false)    // Force rerender page
        articleService.get(param["path"] === undefined ? props.path!! : param["path"], (resp) => {
            data = ArticleModel.fromJSON(resp.data)
            data.content = DOMPurify.sanitize(data.content)
            setLoaded(true)
            scrollToTop()
        })
    }, [param])

    if (!loaded) {
        return (<Box>
                Page loading...
            </Box>
        )
    } else {
        return <Fade in={loaded} timeout={1000}>
            <Box sx={{p: 2,}}>
                <img alt="Home" src={data.categoryImageUrl} height={50}/>
                <HtmlContent html={data.content}/>
            </Box>
        </Fade>
    }
}
